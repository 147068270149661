import {mapMutations} from "vuex";

export default {
    name: "main-menu",
    components: {},
    data() {
        return {}
    },
    created() {

    },
    computed: {},
    methods: {
        ...mapMutations({
            showMobilePopups: 'popups/SHOW_GAME_ON_MOBILE',
            showAuthPopup: 'popups/SHOW_LOGIN'
        }),
        redirectToGame() {
            let isAuth = localStorage.getItem('user_token')
            let userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/windows phone/i.test(userAgent) || /android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                if(isAuth){
                    this.$router.push({name: 'qr'})
                }else{
                    this.showAuthPopup(true)
                }

            }else{
                this.showMobilePopups(true)
            }
        },
        toggleMenu() {

            this.$refs.burger.classList.toggle('open')
            this.$refs.menuOpen.classList.toggle('menu-open')

        },
        smoothScroll(el) {
            const myEl = document.getElementById(el)
            this.$smoothScroll({
                scrollTo: myEl,
            })
            this.$refs.menuOpen.classList.remove('menu-open')
            this.$refs.burger.classList.remove('open')
        },
    }
}
