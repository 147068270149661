import {directive as onClickaway} from 'vue-clickaway';
import {mapActions, mapMutations} from "vuex";

export default {
    directives: {
        onClickaway: onClickaway,
    },
    props: {
        type: {
            type: String,
            default: 'objectData'
        },
        value: {},
        label: {
            type: String,
            default: ''
        },
        progress: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        itemValue: {
            type: String,
            default: 'default'
        },
        itemTitle: {
            type: String,
            default: 'name'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        list: {
            type: Array,
            default: []
        }
    },
    mounted() {
        this.page = 0;
        // this.changeSkip(this.page * this.limit);
        // this.fetchList();
    },
    data() {
        return {
            resetItem: false,
            page: 0,
            openSelect: false,
            selectedItem: this.value,
            selectedFilters: {
                category: '',
                type: {
                    name: this.$t('toDefault'),
                    key: 'default'
                }
            }
        }
    },
    watch: {
        value() {
            this.changeSelect(this.value)
            // this.fetchList();
        },
        selectedFilters: {
            handler() {
                // this.changeFilter(this.selectedFilters);
                // this.changeSkip(this.page * this.limit);
            },
            deep: true
        },

    },
    created() {
        this.selectedItem = this.value;
        // this.changeFilter(this.selectedFilters);

    },
    methods: {
        ...mapActions({
            // fetchList: 'category/GET_LIST',
            // fetchFilters: 'category/GET_FILTERS',
        }),
        ...mapMutations({
            // changeSkip: `category/CHANGE_SKIP`,
            // changeFilter: `category/CHANGE_FILTER`,
            // changeActiveCategory: `category/CHANGE_SELECTED_CATEGOTY`,
        }),
        changeSelect(item) {
            // if (this.$route.query.hasOwnProperty('id'))
            //   this.$router.replace({name: 'catalog', query: ''})

            this.resetItem = false;
            this.openSelect = false;
            this.selectedItem = item;
            this.$emit('input', item);

        },
        away: function () {
            this.openSelect = false
        },

    }
}
