<template>
  <div class="overlay">
    <div class="christmas-tree"></div>
  </div>
</template>
<script>
export default {
  name: 'loader',
}
</script>
<style lang="scss">
@import "../../assets/scss/vars";

.overlay {
  height: 100vh;
  background-color: $red;
  position: fixed;
  width: 100vw;
  z-index: 100;
}

.christmas-tree {
  background: url("../../assets/img/hat_7.png");
  -webkit-animation: gelatine 1s infinite;
  animation: gelatine 1s infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -150px;
  margin-left: -150px;
  width: 311px;
  height: 374px;
  position: relative;
  /* border-radius: 50%; */
  background-repeat: no-repeat;
  //background-color: #0C1E42;
  //background-image: radial-gradient(circle at 50% 18%, yellow 3%, transparent 3.5%), linear-gradient(60deg, #7dae41 8%, transparent 8.5%), linear-gradient(-60deg, #79a83f 8%, transparent 8.5%), linear-gradient(60deg, #75a33d 10%, transparent 10.5%), linear-gradient(-60deg, #719d3b 10%, transparent 10.5%), linear-gradient(60deg, #6d9839 12%, transparent 12.5%), linear-gradient(-60deg, #699237 12%, transparent 12.5%), linear-gradient(80deg, #855726 5%, transparent 5.5%), linear-gradient(-80deg, #7f5324 5%, transparent 5.5%);
  //background-position: 0 0, 100px 80px, 100px 80px, 100px 111.1111111111px, 100px 111.1111111111px, 100px 142.8571428571px, 100px 142.8571428571px, 100px 160px, 100px 160px;
}

.christmas-tree > span {
  position: absolute;
  width: 100%;
  bottom: -30px;
  color: white;
  text-align: center;

}

.christmas-tree:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-transform: rotate(-15deg);
  transform: rotate(-15deg);
  background-image: radial-gradient(circle at 30% 30%, #fff 1.5%, transparent 2.5%), radial-gradient(circle at 80% 70%, #fff 1.5%, transparent 2.5%), radial-gradient(circle at 30% 50%, #fff 1%, transparent 2%), radial-gradient(circle at 70% 85%, #fff 1%, transparent 2%);
  background-size: 100% 100%, 100% 100%, 50% 100%, 50% 50%;
  background-position: 0 0, 0 0, 0 0, 0 0;
  -webkit-animation: snow 6s linear infinite forwards;
  animation: snow 6s linear infinite forwards;
}

@keyframes gelatine {
  from, to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}

@-webkit-keyframes snow {
  100% {
    background-position: 0px 200px, 0 200px, 0px 200px, 0px 200px;
  }
}

@keyframes snow {
  100% {
    background-position: 0px 200px, 0 200px, 0px 200px, 0px 200px;
  }
}

@media only screen and (max-width: 500px) {
  .christmas-tree {
    background: url("../../assets/img/hat_7_small.png");
    background-repeat: no-repeat;
    margin-left: -100px;
    width: 200px;
    height: 200px;
  }
}

</style>

