import {mapMutations} from "vuex";

export default {
    name: "personages",
    components: {},
    data() {
        return {}
    },
    created() {
    },
    computed: {},
    methods: {
        ...mapMutations({
            allPersonages:'popups/SHOW_ALL_PERSONS_POPUP'
        }),
        goToDonate(){
            this.allPersonages(false)
            this.$router.push({name:'donate'})
        }

    }
}
