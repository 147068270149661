import {mapGetters, mapMutations} from 'vuex';

//popups
import mobileGame from "@/components/popups/mobile-game/index.vue";
import login from "@/components/popups/login/index.vue"
import firstPersonage from "@/components/popups/first-personage/index.vue";
import giftPopup from '@/components/popups/gift-product/index.vue'
import allPersonages from '@/components/popups/all-personages/index.vue'


export default {
    name: 'hidden',
    components: {
        mobileGame,
        login,
        firstPersonage,
        giftPopup,
        allPersonages

    },
    computed: {
        ...mapGetters({
            showGamePopup: 'popups/showGamePopup',
            isShowLoginPopup: 'popups/showLoginPopup',
            isShowFirstPersonage: 'popups/showFirstPersonage',
            isShowGiftPopup: 'popups/showGiftProduct',
            showAllPersonsPopup:'popups/showAllPersonsPopup'


        })
    },
    methods: {
        ...mapMutations({
            showMobilePopups: 'popups/SHOW_GAME_ON_MOBILE',
            showLoginPopup: 'popups/SHOW_LOGIN',
            showFirstPersonage: 'popups/SHOW_FIRST_PERSONAGE',
            showGiftPopup: 'popups/SHOW_GIFT_PRODUCT',
            allPersonages:'popups/SHOW_ALL_PERSONS_POPUP'

        })
    }
}
