import {mapActions, mapGetters, mapMutations} from "vuex";
import VFacebookLogin from "vue-facebook-login-component";

export default {
  name: 'facebook-widget',
  data() {
    return {
      facebook: {
        FB: {},
        model: {},
        appId: process.env.VUE_APP_FACEBOOK_IDENTITY,
      },
      updateRender: true,
    }
  },
  props: {
    isRulesAccepted: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VFacebookLogin
  },
  methods: {
    ...mapActions({
      auth: `auth/SING_IN_WITH_FACEBOOK`,
    }),
    getUserData() {
      const {api} = this.facebook.FB;
      api('/me', {fields: 'id, name, email, first_name, last_name'}, (response) => {
        const token = this.facebook.FB.getAccessToken();
        this.auth({token: token, provider: 'facebook'})
          .then(() => {
            this.$toasted.success(this.$t('Auth Success'));
            this.$router.push({name: 'list'}).catch(() => {console.log()});
          })
          .catch(error => {
            if (error.status === 418) {
              this.$toasted.error(error.data.message);
            }
          })
      })
    },
    handleSdkInit({FB}) {
      FB.init({
        xfbml: true,
        version: 'v2.8',
        appId: process.env.VUE_APP_FACEBOOK_IDENTITY,
        status: false,
        cookie: false
      });
      this.facebook.FB = FB;
    },
    mounted() {
      this.handleLogout();
    },
    handleConnect() {

    },
    handleLogout() {
      this.facebook.FB.getLoginStatus(response => {
        if (response.status === 'connected') {
          this.facebook.FB.logout(resp => {
            this.updateRender = false;
            setTimeout(() => {
              this.updateRender = true;
            }, 10)
          });
        }
      })
    },
    login() {
        this.getUserData();
    },
    handleClick(e) {
    },
  },
  destroyed() {
    this.handleLogout();
  }
}
