import axios from 'axios'
import {$http} from '@/utils/https'
import {
    NOVA_POSHTA_GET_DEPARTMENS_LOADING,
    NOVA_POSHTA_GET_TOWNS_LOADING,
    NOVA_POSHTA_SET_DEPARTMENS,
    NOVA_POSHTA_SET_TOWNS, SET_PERSON, SET_USER_DATA,
} from '../mutation-types';

import {
    GET_PERSON,
    NOVA_POSHTA_GET_DEPARTMENS,
    NOVA_POSHTA_GET_TOWNS, SEND_USER_DATA,
} from '../action-types';


const state = {
    invoiceResult: null,
    isInvoiceLoading: false,
    transactionResult: null,
    isTransactionLoading: false,
    orderResult: null,
    productId: null,


    showDialogQuickOrder: false,
    successBuy: false,
    isLoading: false,
    userInfo: {},
    address: {
        town: 'Киев',
    },
    isDepartmensLoading: false,
    isTownsLoading: false,
    towns: [],
    departments: [],

    deliveryNovaPoshtaData: {},
    deliveryCourierData: {},
    deliveryType: 'couirer',
    userData: ''
};

const getters = {

    isDepartmensLoading: state => state.isDepartmensLoading,
    isTownsLoading: state => state.isTownsLoading,
    towns: state => state.towns,
    departments: state => state.departments,
    deliveryNovaPoshtaData: state => state.deliveryNovaPoshtaData,
    userData: state => state.userData
};

const actions = {
    [SEND_USER_DATA]: async ({commit}, payload) => {
        try {
            const result = await $http.post(`user-info`, payload);
            commit(SET_USER_DATA, result.data.data);
        } catch (e) {
            throw e;
        } finally {

        }
    },
    [NOVA_POSHTA_GET_TOWNS]: async ({commit}, payload) => {
        commit(NOVA_POSHTA_GET_TOWNS_LOADING, true);
        try {
            const response = await axios({
                method: 'POST',
                url: "https://api.novaposhta.ua/v2.0/json/",
                data: payload
            })
            commit(NOVA_POSHTA_SET_TOWNS, response.data.data)
            commit(NOVA_POSHTA_SET_DEPARTMENS, [])
        } catch (e) {
            throw e;
        } finally {
            commit(NOVA_POSHTA_GET_TOWNS_LOADING, false);
        }
    },
    [NOVA_POSHTA_GET_DEPARTMENS]: async ({commit}, payload) => {
        commit(NOVA_POSHTA_GET_DEPARTMENS_LOADING, true);
        try {
            const response = await axios({
                method: 'POST',
                url: "https://api.novaposhta.ua/v2.0/json/",
                data: payload
            })
            commit(NOVA_POSHTA_SET_DEPARTMENS, response.data.data)
        } catch (e) {
            throw e;
        } finally {
            commit(NOVA_POSHTA_GET_DEPARTMENS_LOADING, false);
        }
    },
};

const mutations = {
    [NOVA_POSHTA_GET_DEPARTMENS_LOADING](state, status) {
        state.isDepartmensLoading = status
    },
    [NOVA_POSHTA_GET_TOWNS_LOADING](state, status) {
        state.isTownsLoading = status
    },
    [NOVA_POSHTA_SET_TOWNS](state, data) {
        state.towns = data
    },
    [NOVA_POSHTA_SET_DEPARTMENS](state, data) {
        state.departments = data
    },
    [SET_USER_DATA](state, data) {
        state.userData = data
    }
};
export default {
    state,
    getters,
    actions,
    mutations,
};
