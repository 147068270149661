import Vue from 'vue';
import store from '@/store';
import router from '@/router';
import axios from 'axios';
import VueAxios from 'vue-axios';

const camelcaseObjectDeep = require('camelcase-object-deep');

Vue.use(VueAxios, axios);
// localStorage.setItem('user_token', '7uDBY2zTkDSXgvhLy9QowDeSxk9I8khbYAF4nx4p')

export const $http = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('user_token')}`,
        'Accept': 'application/json',
    }
});
$http.interceptors.request.use((config) => {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('user_token')}`;
    return config;
});
$http.interceptors.response.use((response) => {
        response.data = camelcaseObjectDeep(response.data);
        return response;
    }, (error) => {
        switch (error.response.status) {
            case 500: {
                router.push({name: 'server-not-found'}).catch(() => {
                    console.log()
                });
                break;
            }
            case 404: {
                router.push({name: 'page-not-found'}).catch(() => {
                    console.log('404')
                });
                break;
            }
            case 401: {
                router.push({name: '/'}).catch(() => {
                    console.log('401')
                });
                store.commit('auth/REMOVE_TOKEN');
                break;
            }
            default: {
                break;
            }
        }
        return Promise.reject(camelcaseObjectDeep(error.response));
    }
);

export default function install(Vue) {
    Object.defineProperty(Vue.prototype, '$http', {
        get() {
            return $http
        }
    })
}
