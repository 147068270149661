<template>
  <div class="popup">
    <div class="popup-wrap">
      <div class="popup-bg" @click="closePopup()"></div>
      <div class="popup-container" :style="{ 'max-width': maxWidth + 'px' }">
        <div class="popup-top">

        </div>
        <div class="popup-content">
          <slot></slot>
        </div>
        <button class="popup-close" @click="closePopup()">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.3827 6.76953L23.2311 7.61793L7.61793 23.2299L6.76953 22.3821L22.3827 6.76953Z" fill="#FFF9EB"/>
            <path d="M7.61793 6.76953L23.2311 22.3815L22.3827 23.2305L6.76953 7.61853L7.61793 6.76953Z" fill="#FFF9EB"/>
          </svg>
        </button>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: 'popup-wrap',
  data() {
    return {
      isOpen: true
    }
  },
  props: {
    title: {},
    maxWidth: {
      type: Number,
      default: 840
    },

  },
  created() {
    document.getElementById('html').classList.add('hide')
  },
  methods: {
    closePopup() {
      this.$emit('close-popup');
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        document.getElementById('html').classList.add('hide')
      } else {
        document.getElementById('html').classList.remove('hide')
      }
    }
  }
}
</script>
<style lang="scss">
@import "../../assets/scss/vars";

.hide {
  overflow: hidden;
}

.popup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 50;
  overflow-y: auto;

  &-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);
  }

  &-wrap {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    overflow-y: auto;
  }

  &-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 100%;
    max-width: 840px;
    background: $gold;
    padding: 25px 32px;
    z-index: 2;
    border-radius: 30px;
    color: $cream-light;
  }

  &-content {

  }

  &-top {
    width: 100%;
    padding-bottom: 15px;


    display: flex;
    justify-content: space-between;
    align-items: flex-start;

  }

  &-close {
    position: absolute;
    bottom: 36px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 30px;
    height: 30px;

    svg {
      width: 30px;
      height: 30px;
      transition: ease-in-out;
      //color: #9a9a9a;
    }

    &:hover {
      svg {
        opacity: .6;
        transition: ease-in-out;
        transform: scale(1.1);
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .popup-bg {
    position: fixed;
  }
  .popup-wrap {
    height: 100vh;
    //padding-left: 15px;
    //padding-right: 15px;
    display: block;
  }
  .popup-wrap {
    overflow-y: auto;
  }
}

@media screen and (max-width: 500px) {
  .popup-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 50px;
    width: 100%;
    max-width: 840px;
    background: #BBA97E;
    padding: 25px 32px;
    z-index: 2;
    border-radius: 30px;
    color: #FFF9EB;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media screen and (max-width: 425px) {
  .popup-container {
    padding: 15px 20px;
  }
  .popup-top__title {
    font-size: 16px;
  }
  .popup-top {
    padding-bottom: 10px;
  }
}
</style>

