import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter);

const isAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    return next();
  }
  return next('/')
};

const routes = [
    {
        path: '/',
        name: 'main',
        component: () => import('../modules/home/index.vue')
    },
    {
        path: '/qr/:code(\\d+)',
        name: 'code',
        component: () => import('../modules/code/index.vue')
    },

    {
        path: '/qr',
        name: 'qr',
        component: () => import('../modules/game/index.vue'),
        beforeEnter: isAuthenticated,
        redirect: '/qr/heroes',
        children: [
            {
                path: 'heroes',
                name: 'heroes',
                component: () => import('../modules/game/pages/heroes/index.vue'),
                redirect: '/qr/heroes/list',
                children: [
                    {
                        path: 'list',
                        name: 'list',
                        component: () => import('../modules/game/pages/heroes/pages/list/index.vue'),
                    },
                    {
                        path: 'map',
                        name: 'map',
                        component: () => import('../modules/game/pages/heroes/pages/map/index.vue'),
                    },
                ]
            },
            {
                path: 'donate',
                name: 'donate',
                component: () => import('../modules/game/pages/donate/index.vue'),
            },
            {
                path: 'gift',
                name: 'gift',
                component: () => import('../modules/game/pages/gift/index.vue'),
            },
        ]
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        let el = document.querySelector('.app');
        if (to.path !== from.path) {
            if (savedPosition) {
                return savedPosition
            } else {
                el.scrollTop = 0;

                return {x: 0, y: 0}
            }
        }
    }
});

export default router
