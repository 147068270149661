import {mapMutations} from "vuex";

export default {
    name: "mobile-game",
    components: {},
    data() {
        return {}
    },
    created() {
    },
    computed: {},
    methods: {
        ...mapMutations({}),

    }
}
