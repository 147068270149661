<template>
  <div id="app" class="app" style="position: relative;height: auto;overflow: hidden">
    <!--    <mainHeader v-if="$route.name!=='main'"/>-->
    <div class="app-container">
      <div class="wrapper">
        <div class="wrapper-top">
          <transition name="fade" mode="out-in">
            <loader v-if="!isLoaded"/>
          </transition>
          <transition name="component-fade" mode="out-in">
            <router-view/>
          </transition>
        </div>
        <div class="wrapper-bottom" ref="wrapperBottom" v-if="$route.name==='main'">
          <main-footer/>
        </div>
      </div>
    </div>
    <div class="confetti" v-if="$route.path.includes('gift') || showAllPersonsPopup">
      <div :class="`confetti-${index}`" v-for="(item,index) in 150"></div>
    </div>
    <div id="container-snow">
      <div class="snow" v-for="item in 100" ></div>
    </div>
    <hidden/>
  </div>
</template>

<style src="@/assets/scss/main.scss" lang="scss"></style>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Hidden from '@/components/hidden/index.vue';

import MainFooter from '@/components/footer/index.vue';
import {isMobile, isIPad} from '@/helpers/variables/index'

export default {
  name: 'app',
  data() {
    return {
      isLoaded: false
    }
  },
  components: {
    Hidden,
    MainFooter,

  },
  computed: {
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
      variables: `setting/variables`,
      showAllPersonsPopup: 'popups/showAllPersonsPopup'
    })
  },
  created() {
    function createSnowFlake() {
      let cmb = characterCombo();
      let flake =
          '<div class="snowflake"><s>' +
          cmb +
          "</s><s>" +
          "<s>" +
          cmb +
          "</s><s>" +
          "<s>" +
          cmb +
          "</s><s>" +
          "<s>" +
          cmb +
          "</s><s>" +
          "<s>" +
          cmb +
          "</s><s>" +
          "<s>" +
          cmb +
          "</s></div>";
      let div = document.createElement("div");
      let left = window.event.clientX - 20;
      let top = window.event.clientY + 25;
      div.setAttribute("id", "content");
      div.style.position = "absolute";
      div.style.left = left + "px";
      div.style.top = top + "px";
      div.innerHTML += flake;
      document.getElementById("container-snow").appendChild(div);
      setTimeout(function () {
        document.getElementById("content").remove();
      }, 10000);
    }

    setTimeout(() => {
      this.isLoaded = true
    }, 1800)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
  mounted() {
    if (window.innerWidth <= 550) {
      window.addEventListener("scroll", this.onScroll)
    }

    if (!isMobile.any()) {
      document.querySelector('body').classList.add('hover');
    }
    if (!isIPad.iOS()) {
      document.querySelector('body').classList.add('iPad');
    }
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
    onScroll(e) {

      let page = document.querySelector('body')

      if (window.scrollY >= page.offsetHeight - window.innerHeight - 180) {
        if (document.getElementById('mainMenu')) {
          document.getElementById('mainMenu').classList.add('mobileMenu')
        }

      }
      if (window.scrollY < page.offsetHeight - window.innerHeight - 180) {
        if (document.getElementById('mainMenu'))
          document.getElementById('mainMenu').classList.remove('mobileMenu')
      }
    }
  }
}
</script>
