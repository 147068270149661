//auth
export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const CHANGE_POPUP_RESTORE_PASSWORD = 'CHANGE_POPUP_RESTORE_PASSWORD';
export const CHANGE_POPUP_NEW_PASSWORD = 'CHANGE_POPUP_NEW_PASSWORD';
export const SET_LOADING_REGISTRATION = 'SET_LOADING_REGISTRATION';
export const CHANGE_POPUP_LDAP = 'CHANGE_POPUP_LDAP';
export const SING_IN_WITH_LDAP_LOADING = 'SING_IN_WITH_LDAP_LOADING';
export const CHECK_EMAIL_LOADING = 'CHECK_EMAIL_LOADING';
export const CHANGE_LOADING_SING_IN = 'CHANGE_LOADING_SING_IN';
export const CHANGE_LOADING = 'CHANGE_LOADING';


//settings
export const SET_LANGUAGE_LIST = 'SET_LANGUAGE_LIST';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const NOVA_POSHTA_GET_TOWNS_LOADING = 'NOVA_POSHTA_GET_TOWNS_LOADING';
export const NOVA_POSHTA_SET_TOWNS = 'NOVA_POSHTA_SET_TOWNS';
export const NOVA_POSHTA_GET_DEPARTMENS_LOADING = 'NOVA_POSHTA_GET_DEPARTMENS_LOADING';
export const NOVA_POSHTA_SET_DEPARTMENS = 'NOVA_POSHTA_SET_DEPARTMENS';
export const SET_CHECKED_DONATE = 'SET_CHECKED_DONATE'
export const SET_DELIVERY_DATA = 'SET_DELIVERY_DATA'

//popups
export const SHOW_GAME_ON_MOBILE = 'SHOW_GAME_ON_MOBILE'
export const SHOW_LOGIN = 'SHOW_LOGIN'
export const SHOW_FIRST_PERSONAGE = 'SHOW_FIRST_PERSONAGE'
export const SHOW_GIFT_PRODUCT = 'SHOW_GIFT_PRODUCT'
export const SHOW_PERSON = 'SHOW_PERSON'
export const SET_PERSON_INFO = 'SET_PERSON_INFO'
export const SHOW_ALL_PERSONS_POPUP = 'SHOW_ALL_PERSONS_POPUP'

//game
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_GAME_DATE = 'SET_GAME_DATE'
export const SET_PERSON = 'SET_PERSON'
export const SET_USER_AUTH_DATA='SET_USER_AUTH_DATA'


//order
export const SET_USER_DATA='SET_USER_DATA'