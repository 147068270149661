//auth
export const GET_TOKEN = 'GET_TOKEN';
export const LOGOUT = 'LOGOUT';
export const REGISTRATION = 'REGISTRATION';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SING_IN_WITH_GOOGLE = 'SING_IN_WITH_GOOGLE';
export const SING_IN_WITH_FACEBOOK = 'SING_IN_WITH_FACEBOOK';
//setting
export const GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST';
export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const GET_CATEGORIES_MENU = 'GET_CATEGORIES_MENU';
export const GET_CATEGORY_FILTERS = 'GET_CATEGORY_FILTERS';
export const GET_VARIABLES = 'GET_VARIABLES';


export const NOVA_POSHTA_GET_TOWNS = 'NOVA_POSHTA_GET_TOWNS';
export const NOVA_POSHTA_GET_DEPARTMENS = 'NOVA_POSHTA_GET_DEPARTMENS';

//game
export const GET_GAME_DATE = 'GET_GAME_DATE'
export const GET_USER_INFO = 'GET_USER_INFO'
export const GET_PERSON = 'GET_PERSON'
export const RESET_CARDS = 'RESET_CARDS'
export const SEND_USER_DATA = 'SEND_USER_DATA'
export const MAKE_DONATE = 'MAKE_DONATE'
export const GET_USER_AUTH_INFO = 'GET_USER_AUTH_INFO'