import Vue from 'vue';
import Loader from '@/components/misc/loader.vue';
import PopupWrap from '@/components/misc/popup-wrap.vue';
import mainButton from '@/components/main-button/index.vue';
import smallLoader from '@/components/misc/small-loader.vue';
import menu from '@/components/main-menu/index.vue'
import VueSmoothScroll from 'vue2-smooth-scroll'
import mainSelect from '@/components/main-select/index.vue'
import autocomplete from '@/components/autocomplete/index.vue'
import VueTheMask from 'vue-the-mask';

Vue.use(VueTheMask);
Vue.component('autocomplete', autocomplete);
Vue.component('main-select', mainSelect);
Vue.component('main-menu', menu);
Vue.component('small-loader', smallLoader);
Vue.component('loader', Loader);
Vue.component('popup-wrap', PopupWrap);
Vue.component('main-button', mainButton);
Vue.component('v-pagination', window['vue-plain-pagination']);

Vue.use(VueSmoothScroll, {
    duration: 700,
    updateHistory: false,
    offset: 0,
})
