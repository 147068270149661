import {mapActions, mapGetters, mapMutations} from "vuex";
import {FETCH_USER_DATA, LINK_WITH_GOOGLE, SING_IN_WITH_GOOGLE} from '@/store/action-types';
import Vue from 'vue';
import GSignInButton from 'vue-google-signin-button';

Vue.use(GSignInButton);

export default {
  name: 'google-widget',
  data() {
    return {
      // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
      googleSignInParams: {
        client_id: process.env.VUE_APP_GOOGLE_IDENTITY,
        callback: this.onSignInSuccess,
        auto_select: true
      },
    }
  },
  props: {
    isRulesAccepted: {
      type: Boolean,
      default: false
    }
  },
  components: {
    GSignInButton
  },
  mounted() {
    window.google.accounts.id.initialize(this.googleSignInParams)
  },
  methods: {
    ...mapActions({
      auth: `auth/${SING_IN_WITH_GOOGLE}`,
    }),
    googleSignInPrompt() {
      window.google.accounts.id.prompt();
    },
    onSignInSuccess(googleUser) {
      const token = googleUser.credential;

      this.auth({token: token, provider: 'google'})
      .then(() => {
        this.$toasted.success(this.$t('Auth Success'));
        this.$router.push({name: 'list'}).catch(() => {console.log()});
      })
      .catch(error => {
        console.log('Google error', error);
        if (error.status === 418) {
          this.$toasted.error(error.data.message);
        }
      })

    },
    onSignInError(error) {
      console.log('onSignInError', error);
    }
  }
}
