import {mapMutations} from "vuex";

export default {
    name: "first-personage",
    components: {},
    data() {
        return {
            checked:false
        }
    },
    created() {
    },
    computed: {},
    methods: {
        ...mapMutations({
            showFirstHeroPopup: 'popups/SHOW_FIRST_PERSONAGE',
            showLogin: 'popups/SHOW_LOGIN',
        }),
        showAuthPopup() {
            this.showFirstHeroPopup(false);
            this.showLogin(true);
        }
    }
}
