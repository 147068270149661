import {
    SET_CHECKED_DONATE, SET_DELIVERY_DATA,
    SET_LANGUAGE_LIST,
    SET_TRANSLATIONS,
} from '../mutation-types';

import {} from '../action-types';

const state = {
    menu: null,
    config: null,
    configLoading: false,
    menuLoading: false,
    locales: null,
    translations: null,
    filterCategories: null,
    filterCategoriesLoading: false,
    variables: null,
    checkedDonate: false,
    deliveryData: false,

}

const getters = {
    filterCategories: state => state.filterCategories,
    variables: state => state.variables,
    filterCategoriesLoading: state => state.filterCategoriesLoading,
    menu: state => state.menu,
    menuLoading: state => state.menuLoading,
    locales: state => state.locales,
    translations: state => state.translations,
    config: state => state.config,
    configLoading: state => state.configLoading,
    checkedDonate: state => state.checkedDonate,
    deliveryData: state => state.deliveryData,

};

const actions = {};

const mutations = {
    [SET_LANGUAGE_LIST](state, status) {
        state.locales = status;
    },

    [SET_TRANSLATIONS](state, status) {
        state.translations = status;
    },
    [SET_CHECKED_DONATE](state, status) {
        state.checkedDonate = status
    },
    [SET_DELIVERY_DATA](state, data) {
        state.deliveryData = data

    }

};
export default {
    state,
    getters,
    actions,
    mutations,
};
