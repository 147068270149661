// import {$http} from '@/utils/https'

import {
    CHANGE_LOGIN_POPUP,
    CHANGE_REGISTRATION_POPUP,
    CHANGE_RESET_POPUP,
    CHANGE_RESTORE_POPUP,
    SET_PERSON_INFO, SHOW_ALL_PERSONS_POPUP,
    SHOW_FIRST_PERSONAGE,
    SHOW_GAME_ON_MOBILE,
    SHOW_GIFT_PRODUCT,
    SHOW_LOGIN,
    SHOW_PERSON,
} from '../mutation-types';

import {} from '../action-types';

const state = {
    showGamePopup: false,
    showLoginPopup: false,
    showFirstPersonage: false,
    showGiftProduct: false,
    showPersonPopup: false,
    personInfo: '',
    showAllPersonsPopup: false
};

const getters = {
    showGamePopup: state => state.showGamePopup,
    showLoginPopup: state => state.showLoginPopup,
    showFirstPersonage: state => state.showFirstPersonage,
    showGiftProduct: state => state.showGiftProduct,
    showPersonPopup: state => state.showPersonPopup,
    personInfo: state => state.personInfo,
    showAllPersonsPopup: state => state.showAllPersonsPopup
};

const actions = {};

const mutations = {
    [SHOW_GAME_ON_MOBILE](state, status) {
        state.showGamePopup = status;
    },
    [SHOW_LOGIN](state, status) {
        state.showLoginPopup = status;
    },
    [SHOW_FIRST_PERSONAGE](state, status) {
        state.showFirstPersonage = status;
    },
    [SHOW_GIFT_PRODUCT](state, status) {
        state.showGiftProduct = status;
    },
    [SHOW_PERSON](state, status) {
        state.showPersonPopup = status;
    },
    [SET_PERSON_INFO](state, data) {
        state.personInfo = data;
    },
    [SHOW_ALL_PERSONS_POPUP](state, status) {
        state.showAllPersonsPopup = status
    }
};
export default {
    state,
    getters,
    actions,
    mutations,
};
