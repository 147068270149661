import {$http} from '@/utils/https';
// import {globalLoader, fixBody} from '@/helpers/variables/index';
import {
    SET_USER_INFO,
    SET_GAME_DATE, SET_PERSON, SET_USER_AUTH_DATA
} from '../mutation-types';
import {
    GET_GAME_DATE, GET_PERSON, GET_USER_AUTH_INFO,
    GET_USER_INFO, MAKE_DONATE, RESET_CARDS,
} from '../action-types';

const state = {
    userInfo: '',
    gameData: '',
    openedPerson: '',
    authUserData: ''
};

const getters = {
    userInfo: state => state.userInfo,
    gameData: state => state.gameData,
    openedPerson: state => state.openedPerson,
    authUserData: state => state.authUserData
};

const actions = {
    [MAKE_DONATE]: async ({commit}, payload) => {
        try {
            const result = await $http.post(`make-donation`, payload);
            // commit(SET_PERSON, result.data.data);

        } catch (e) {
            throw e;
        } finally {

        }
    },
    [RESET_CARDS]: async ({commit}, payload) => {
        try {
            const result = await $http.post(`reset/cards`, payload);

        } catch (e) {
            throw e;
        } finally {

        }
    },
    [GET_PERSON]: async ({commit}, payload) => {
        try {
            const result = await $http.post(`activate-token/${payload.token}`, payload);
            commit(SET_PERSON, result.data.data);
        } catch (e) {
            throw e;
        } finally {

        }
    },
    [GET_USER_INFO]: async ({commit}, payload) => {
        try {
            const result = await $http.get('/user-info', payload);
            commit(SET_USER_INFO, result.data.data);
        } catch (e) {
            throw e;
        } finally {

        }
    },
    [GET_USER_AUTH_INFO]: async ({commit}, payload) => {
        try {
            const result = await $http.get('/user', payload);
            commit(SET_USER_AUTH_DATA, result.data.data);
        } catch (e) {
            throw e;
        } finally {

        }
    },
    [GET_GAME_DATE]: async ({commit}, payload) => {
        try {
            const result = await $http.get('/game-character', payload);
            commit(SET_GAME_DATE, result.data.data);
        } catch (e) {
            throw e;
        } finally {

        }
    },
};

const mutations = {
    [SET_USER_INFO](state, data) {
        state.userInfo = data;
    },
    [SET_GAME_DATE](state, data) {
        state.gameData = data;
    },
    [SET_PERSON](state, data) {
        state.openedPerson = data;
    },
    [SET_USER_AUTH_DATA](state, data) {
        state.authUserData = data;
    },

};
export default {
    state,
    getters,
    actions,
    mutations,
};
