import {mapMutations} from "vuex";
import facebook from "@/components/widgets/facebook/index.vue";
import google from "@/components/widgets/google/index.vue";

export default {
    name: "login",
    components: {
        facebook,
        google
    },
    data() {
        return {
            checked:true
        }
    },
    created() {
    },
    computed: {},
    methods: {
        ...mapMutations({}),

    }
}
